<template lang="pug">
    .main-wrapper.procedimentos-credenciado-listar
        header.main-wrapper-header
            .p-grid.p-align-center
                .p-col-3
                    Button.p-button-secondary.btn-back(icon='jam jam-chevrons-left' @click='$router.go(-1)')
                .p-col-9.ta-right
                    h1.text-header.text-secondary MedClub / <b>Procedimentos do Estabelecimento</b>

        Dialog.dialogEditarObservacoes(header='Editar observações' :visible.sync='dialogEditarObservacoes' :modal='true')
            .p-grid.p-fluid
                .p-col-12
                    span <b>Procedimento: </b> {{ dialogEditarObservacoes_data.nm_procedimento }}
                .p-col-12
                    label.form-label Informações de atendimento:
                    Textarea.textarea-informacoes(v-model='dialogEditarObservacoes_data.ds_informacoes' placeholder='(Opcional)' :autoResize="false" rows="5")
                .p-col-12
                    label.form-label Preparo do procedimento:
                    Textarea.textarea-preparo(v-model='dialogEditarObservacoes_data.ds_preparo' placeholder='(Opcional)' :autoResize="false" rows="5")
                .p-col-12.ta-center
                    ProgressSpinner.waitingObservacoes(v-if='waitingObservacoes' strokeWidth='6')
                    Button(v-else label='Salvar' style='max-width:160px' @click='salvarObservacoes')
       
        Dialog(header='Subgrupos de ' :visible.sync='dialogSubgrupos' :modal='true')
            .ta-center(style='max-width: 500px')
                DataTable(:value="subgrupos")
                    Column(headerStyle='width: 4%;' field='ie_valor' header='Código')
                        template(#body='props')
                            span(v-tooltip.top="props.data.ie_valor" style='cursor: pointer;') {{ props.data.ie_valor }}
                    Column(headerStyle='width: 15%;' field='ds_valor' header='Subgrupo')
                        template(#body='props')
                            span(v-tooltip.top="props.data.ds_valor" style='cursor: pointer;') {{ props.data.ds_valor }}      
        
        Panel.my-2(header='Filtros' :toggleable='true' :collapsed='windowInnerWidth < 576' v-if='waiting == false' mode="indeterminate")
            .p-grid.p-fluid
                .p-col-12.p-md-3
                    label.form-label Status:
                    .p-inputgroup
                        Dropdown(
                            :options='options.status'
                            placeholder='TODOS'
                            optionLabel='text'
                            optionValue='value'
                            dataKey='value'
                            v-model='filters.ie_status'
                            @change='applyFilters()'
                        )
                .p-col-12.p-md-3
                    label.form-label Procedimento:
                    .p-inputgroup
                        InputText(placeholder='Procedimento'
                            @keyup.enter.native='applyFilters()'
                            @input='(value) => { if (!value) applyFilters() }'
                            v-model='filters.ds_procedimento'
                            v-tooltip.top.focus="'Aperte ENTER para pesquisar'"
                        )
                        Button(icon='jam jam-search' @click='applyFilters()')
                .p-col-12.p-md-3
                    label.form-label Subgrupos:
                    .p-inputgroup
                    MultiSelect(
                        :options='options.subgrupos'
                        placeholder='TODOS'
                        optionLabel='text'
                        optionValue='value'
                        dataKey='value'
                        v-model='filters.cd_subgrupos'
                        @change='applyFilters()'
                    )
        ProgressBar(v-if='waiting' mode="indeterminate")
        div(v-else-if='!list.length')
            p.ta-center.text-secondary(style='margin-top: 40px;') Nenhum registro encontrado.
        div(v-else)
            DataView.dataview.my-2(:value="list" layout="grid")
                template(#grid="props")
                    .p-col-12.p-md-3(style="padding: .5em" :class="{ disabled: !props.data.ie_status }")
                        Panel.panel-list.ta-center(:header="props.data.nm_procedimento" style='position: relative')
                            .ta-left
                                p <b>Código:</b> {{ props.data.cd_cbhpm }}
                                p <b>Nome do Procedimento:</b> {{ props.data.nm_procedimento }}
                                p <b>Valor negociado:</b> {{ props.data.nr_valor_negociado_f }}
                                p <b>Tarifa MedClub:</b> {{ props.data.nr_valor_tarifa_f }}


            Paginator.dataview(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")

            Panel.datatable(header='Lista de Procedimentos')
                Paginator.mb-1(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")
                DataTable(:value="list" class='responsive' @sort='onSort' :sortField="order.field" :sortOrder="order.sortOrder")
                    Column(headerStyle='width: 50px' bodyStyle='text-align:center')
                        template(#body='props')
                            .status-indicator(:class="{ off: ! props.data.ie_status }")
                                i.jam(:class="{ 'jam-check': props.data.ie_status, 'jam-minus': !props.data.ie_status }")
                    Column(headerStyle='width: 100px' field='cd_cbhpm' header='Código' :sortable="true")
                    Column(headerStyle='width: 100px' field='ds_sigla' header='Sigla' :sortable="true")
                    Column(headerStyle='width: 300px' header='Procedimentos' field="nm_procedimento" :sortable="true")
                        template(#body='props')
                            p(style='margin:2px 0') <b>{{ props.data.nm_procedimento }}</b>
                            p(style='margin:2px 0') {{ props.data.ds_procedimento }}
                    Column(headerStyle='width: 250px' header='Informações' field="ds_informacoes")
                        template(#body='props')
                            p(:id="`INFO_${props.data.id}`" :class="{ 'text-ellipsis': !expandedRows.includes(props.data.id) }")
                                span {{ props.data.ds_informacoes }}
                                span(
                                    v-tooltip.top="`Clique para ${ expandedRows.includes(props.data.id) ? 'retrair' : 'expandir' }`"
                                    @click="expandRow(props.data)" style="cursor: pointer;" hidden
                                ) {{ props.data.ds_informacoes }}
                    Column(headerStyle='width: 250px' header='Preparo' field="ds_preparo")
                        template(#body='props')
                            p(:id="`PREPARO_${props.data.id}`"
                                :class="{ 'text-ellipsis': !expandedRows.includes(props.data.id) }")
                                span {{ props.data.ds_preparo }}
                                span(
                                    v-tooltip.top="`Clique para ${ expandedRows.includes(props.data.id) ? 'retrair' : 'expandir' }`"
                                    @click="expandRow(props.data)" style="cursor: pointer;" hidden
                                ) {{ props.data.ds_preparo }}
                    Column(headerStyle='width: 100px' header='Ações')
                        template(#body='props')
                            .ta-center
                                Button.p-button-raised.p-button-rounded.mr-1(
                                    v-tooltip.top="'Editar observações'"
                                    icon="jam jam-document"
                                    @click="dialogEditarObservacoes_data = props.data; dialogEditarObservacoes = true"
                                )
                                Button.p-button-raised.p-button-rounded.p-button-secondary(
                                    v-if='props.data.cd_subgrupos.length'
                                    style='background-color: #9B0079; color: white'
                                    v-tooltip.top="'Subgrupos'"
                                    icon="jam jam-merge"
                                    @click='openDialogSubgrupos(props.data.cd_subgrupos)'
                                )
                Paginator(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")
</template>

<style lang="scss">
    .procedimentos-credenciado-listar {
        .dataview {
            @media all and (min-width: 577px) {
                display: none;
            }
        }
        .datatable {
            @media all and (max-width: 576px) {
                display: none;
            }
            .cell {
                padding: 16px 0;
                text-align: center;
                &.ex  {  background-color: #e4f8e1; }
                &.a  { background-color: #faf3dd; }
            }
            .text-ellipsis {
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;
            }
        }
        .dialogEditarObservacoes {
            width: 96%;
            max-width: 600px;
            textarea {
                height: 200px;
            }
        }
        .status-indicator {
            display: inline-block;
            font-size: 0;
            width: 24px;
            height: 24px;
            line-height: 36px;
            border-radius: 50%;
            background-color: #94c860;
            border: 1px solid #84b553;
            transition: 250ms;
            &.off {
                background-color: #d33838;
                border: 1px solid #b33030;
            }
            .jam {
                color: #fff;
            }
        }
        .waitingObservacoes {
            width: 29px;
            height: auto;
        }
        .textarea-informacoes,
        .textarea-preparo {
            height: 200px !important;
            overflow-y: auto !important;
            resize: none;
        }
    }
</style>

<script>
    import Button from 'primevue/button'
    import Column from 'primevue/column'
    import DataTable from 'primevue/datatable'
    import DataView from 'primevue/dataview'
    import Dialog from 'primevue/dialog'
    import Dropdown from 'primevue/dropdown'
    import InputText from 'primevue/inputtext'
    import Paginator from 'primevue/paginator'
    import Panel from 'primevue/panel'
    import ProgressBar from 'primevue/progressbar'
    import ProgressSpinner from 'primevue/progressspinner'
    import Textarea from 'primevue/textarea'
    import Tooltip from 'primevue/tooltip'
    import MultiSelect from 'primevue/multiselect'
    import { ProcedimentosEstabelecimento, DominioValor } from './../../middleware'
    import wsConfigs from './../../middleware/configs'
    import { processDominiosResponse } from "@/utils";

    export default {
        created () { 
            this.getDominios()
            this.applyFilters() 
            },
        components: { Button, Column, DataTable, DataView, Dialog, Dropdown, InputText,
            Paginator, Panel, ProgressBar, ProgressSpinner, Textarea, Tooltip, MultiSelect },
        directives: { tooltip: Tooltip },
        data () {
            return {
                list: [],
                subgrupos: [],
                windowInnerWidth: window.innerWidth,
                waiting: false,
                waitingObservacoes: false,
                dialogSubgrupos:  false,
                dialogEditarObservacoes: false,
                dialogEditarObservacoes_data: {},
                expandedRows: [],
                filters: {
                    ds_procedimento: null,
                    ie_status: true,
                    cd_subgrupos: [],
                },
                options: {
                    subgrupos:[],
                    status: [
                        {text: "TODOS", value: null},
                        {text: "Ativo", value: true},
                        {text: "Inativo", value: false},
                    ]
                },
                paginator: {
                    page: this.$route.query.pg ? this.$route.query.pg : 1,
                    per_page: wsConfigs.paginator_perPage,
                    count: 0
                },
                order: {
                    field: 'cd_cbhpm',
                    sortOrder: 1
                }
            }
        },
        methods: {
            onSort (ev) {
                this.order.field = ev.sortField
                this.order.sortOrder = ev.sortOrder
                this.applyFilters()
            },
            onPage (ev) {
                this.paginator.page = ev.page + 1
                this.applyFilters()
            },
            expandRow(data) {
                if (this.expandedRows.includes(data.id)) {
                    let index = this.expandedRows.indexOf(data.id)
                    if (index !== -1) this.expandedRows.splice(index, 1)
                } else {
                    this.expandedRows.push(data.id)
                }
            },
            checkOverflow(elementId) {
                let element = document.getElementById(elementId)
                if (!element) return

                let currentOverflow = element.style.overflow

                if ( !currentOverflow || currentOverflow === "visible" )
                    element.style.overflow = "hidden"

                let isOverflowing = element.clientHeight < element.scrollHeight
                if (isOverflowing) {
                    for (let i = 0; i < element.children.length; i++) {
                        element.children[i].hidden = !element.children[i].hidden
                    }
                }

                element.style.overflow = currentOverflow
            },
            getList (params) {
                this.waiting = true
                this.expandedRows = []
                return ProcedimentosEstabelecimento.getProcedimentosCredenciado(params).then(response => {
                    if (response.status == 200) {
                        this.paginator.count = response.data.count
                        this.list = response.data.results

                        this.$nextTick().then(() => {
                            this.list.forEach(item => {
                                this.checkOverflow(`INFO_${item.id}`)
                                this.checkOverflow(`PREPARO_${item.id}`)
                            })
                        })
                    }
                    this.waiting = false
                })
            },
            applyFilters () {
                let params = { paginacao: true, page: this.paginator.page, per_page: this.paginator.per_page }

                params.order = `${ this.order.sortOrder === -1 ? '-' : '' }${ this.order.field }`

                if (this.$route.query.pg != this.paginator.page) this.$router.replace( { query: { pg: this.paginator.page } } )
                Object.keys(this.filters).forEach((key) => {
                    if (this.filters[key] || typeof this.filters[key] === 'boolean') params[key] = this.filters[key]
                })
                this.getList(params)
            },
            getDominios() {
				DominioValor.findAll({ds_mnemonico: ['SUBGRUPOS']}).then(response => {
					this.dominios = processDominiosResponse(response)
					if (response.status === 200) {
						this.options.subgrupos = response.data['SUBGRUPOS'].valores.map(subgrupo => ({
                            value: subgrupo.ie_valor,
                            text: subgrupo.ds_valor ,
                            ds_subgrupo: subgrupo.ds_valor,
                            id: subgrupo.ie_valor
                        }))
					}
				});
			},
            openDialogSubgrupos(subgrupos) {
				this.subgrupos = subgrupos
				this.dialogSubgrupos = true
			},
            salvarObservacoes () {
                let dataSend = {
                    id: this.dialogEditarObservacoes_data.id,
                    ds_informacoes: this.dialogEditarObservacoes_data.ds_informacoes,
                    ds_preparo: this.dialogEditarObservacoes_data.ds_preparo
                }
                this.waitingObservacoes = true
                ProcedimentosEstabelecimento.saveProcedimentosCredenciado(dataSend).then(response => {
                    this.waitingObservacoes = false
                    if ([200, 201, 204].includes(response.status)) {
                        this.$toast.success('Procedimento atualizado com sucesso.', { duration: 3000 })
                        this.applyFilters()
                    } else if (response.data.detail) {
                        if (typeof response.data.detail == 'string')
                            this.$toast.error(response.data.detail, { duration: 3000 })
                        else response.data.detail.forEach(msg => this.$toast.error(msg, { duration: 3000 }))
                    }
                })
            },
        }
    }
</script>
